@font-face {
  font-family: "npt-icons";
  src: url("../fonts/npt-icons.eot") format("embedded-opentype"),
    url("../fonts/npt-icons.woff") format("woff"),
    url("../fonts/npt-icons.ttf") format("truetype"),
    url("../fonts/npt-icons.svg") format("svg");
}

i[class^="npt-i-"]:before,
i[class*=" npt-i-"]:before {
  font-family: npt-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.npt-i-eg-achr:before {
  content: "\f101";
}
.npt-i-eg-ag:before {
  content: "\f102";
}
.npt-i-eg-am:before {
  content: "\f103";
}
.npt-i-eg-apv:before {
  content: "\f104";
}
.npt-i-eg-arrow:before {
  content: "\f105";
}
.npt-i-eg-battery:before {
  content: "\f106";
}
.npt-i-eg-boiler:before {
  content: "\f107";
}
.npt-i-eg-breaker:before {
  content: "\f108";
}
.npt-i-eg-bus:before {
  content: "\f109";
}
.npt-i-eg-cabinet:before {
  content: "\f10a";
}
.npt-i-eg-cable:before {
  content: "\f10b";
}
.npt-i-eg-car-breaker:before {
  content: "\f10c";
}
.npt-i-eg-car:before {
  content: "\f10d";
}
.npt-i-eg-cb:before {
  content: "\f10e";
}
.npt-i-eg-cc:before {
  content: "\f10f";
}
.npt-i-eg-cogeneration-plant:before {
  content: "\f110";
}
.npt-i-eg-combined-cycle-plant:before {
  content: "\f111";
}
.npt-i-eg-compensating-reactor:before {
  content: "\f112";
}
.npt-i-eg-control-cooling-system:before {
  content: "\f113";
}
.npt-i-eg-conv:before {
  content: "\f114";
}
.npt-i-eg-cooling-system:before {
  content: "\f115";
}
.npt-i-eg-copt:before {
  content: "\f116";
}
.npt-i-eg-ct:before {
  content: "\f117";
}
.npt-i-eg-current-transformer-phase:before {
  content: "\f118";
}
.npt-i-eg-current-transformer-winding:before {
  content: "\f119";
}
.npt-i-eg-dfz:before {
  content: "\f11a";
}
.npt-i-eg-disconnector:before {
  content: "\f11b";
}
.npt-i-eg-dz:before {
  content: "\f11c";
}
.npt-i-eg-efn:before {
  content: "\f11d";
}
.npt-i-eg-fan:before {
  content: "\f11e";
}
.npt-i-eg-fgc-alter:before {
  content: "\f11f";
}
.npt-i-eg-fgc:before {
  content: "\f120";
}
.npt-i-eg-fun-null:before {
  content: "\f121";
}
.npt-i-eg-fuse:before {
  content: "\f122";
}
.npt-i-eg-gas-protection:before {
  content: "\f123";
}
.npt-i-eg-generating-unit:before {
  content: "\f124";
}
.npt-i-eg-generator:before {
  content: "\f125";
}
.npt-i-eg-gil:before {
  content: "\f126";
}
.npt-i-eg-high-frequency-choke:before {
  content: "\f127";
}
.npt-i-eg-high-frequency-filter:before {
  content: "\f128";
}
.npt-i-eg-high-frequency-setting-element:before {
  content: "\f129";
}
.npt-i-eg-high-voltage-lead:before {
  content: "\f12a";
}
.npt-i-eg-hydro-power-plant:before {
  content: "\f12b";
}
.npt-i-eg-ifl:before {
  content: "\f12c";
}
.npt-i-eg-jumper:before {
  content: "\f12d";
}
.npt-i-eg-load-switch:before {
  content: "\f12e";
}
.npt-i-eg-mes:before {
  content: "\f12f";
}
.npt-i-eg-motor:before {
  content: "\f130";
}
.npt-i-eg-mtz:before {
  content: "\f131";
}
.npt-i-eg-nuclear-power-plant:before {
  content: "\f132";
}
.npt-i-eg-nuclear-reactor:before {
  content: "\f133";
}
.npt-i-eg-oil-level:before {
  content: "\f134";
}
.npt-i-eg-phase-tap-changer:before {
  content: "\f135";
}
.npt-i-eg-photo-voltaic-unit:before {
  content: "\f136";
}
.npt-i-eg-pmes-alter:before {
  content: "\f137";
}
.npt-i-eg-pmes:before {
  content: "\f138";
}
.npt-i-eg-potential-transformer-phase:before {
  content: "\f139";
}
.npt-i-eg-potential-transformer-winding:before {
  content: "\f13a";
}
.npt-i-eg-power-transformer-end:before {
  content: "\f13b";
}
.npt-i-eg-psh-ltc:before {
  content: "\f13c";
}
.npt-i-eg-psh-phase:before {
  content: "\f13d";
}
.npt-i-eg-psh:before {
  content: "\f13e";
}
.npt-i-eg-reactor-twin:before {
  content: "\f13f";
}
.npt-i-eg-reactor:before {
  content: "\f140";
}
.npt-i-eg-region:before {
  content: "\f141";
}
.npt-i-eg-res:before {
  content: "\f142";
}
.npt-i-eg-roundknife:before {
  content: "\f143";
}
.npt-i-eg-rrc:before {
  content: "\f144";
}
.npt-i-eg-sar:before {
  content: "\f145";
}
.npt-i-eg-scr:before {
  content: "\f146";
}
.npt-i-eg-sg:before {
  content: "\f147";
}
.npt-i-eg-sk:before {
  content: "\f148";
}
.npt-i-eg-smc:before {
  content: "\f149";
}
.npt-i-eg-tap-changer:before {
  content: "\f14a";
}
.npt-i-eg-tcf:before {
  content: "\f14b";
}
.npt-i-eg-tcr:before {
  content: "\f14c";
}
.npt-i-eg-technology-protection:before {
  content: "\f14d";
}
.npt-i-eg-temp-oil:before {
  content: "\f14e";
}
.npt-i-eg-tnznp:before {
  content: "\f14f";
}
.npt-i-eg-to:before {
  content: "\f150";
}
.npt-i-eg-transformer-at3-ltc:before {
  content: "\f151";
}
.npt-i-eg-transformer-at3:before {
  content: "\f152";
}
.npt-i-eg-transformer-t1-ltc:before {
  content: "\f153";
}
.npt-i-eg-transformer-t2-b-ltc:before {
  content: "\f154";
}
.npt-i-eg-transformer-t2-b:before {
  content: "\f155";
}
.npt-i-eg-transformer-t2-ltc:before {
  content: "\f156";
}
.npt-i-eg-transformer-t2:before {
  content: "\f157";
}
.npt-i-eg-transformer-t3-itc:before {
  content: "\f158";
}
.npt-i-eg-transformer-t3:before {
  content: "\f159";
}
.npt-i-eg-transformer-t4-ltc:before {
  content: "\f15a";
}
.npt-i-eg-transformer-t4:before {
  content: "\f15b";
}
.npt-i-eg-transformer-tank:before {
  content: "\f15c";
}
.npt-i-eg-transformer-th:before {
  content: "\f15d";
}
.npt-i-eg-turbine:before {
  content: "\f15e";
}
.npt-i-eg-tznp:before {
  content: "\f15f";
}
.npt-i-eg-usage-point:before {
  content: "\f160";
}
.npt-i-eg-water-gas-in-oil-control:before {
  content: "\f161";
}
.npt-i-eg-wind-power-plant:before {
  content: "\f162";
}
.npt-i-eg-wind-unit:before {
  content: "\f163";
}
.npt-i-eg-winding-temperature-control:before {
  content: "\f164";
}
.npt-i-eg-zmn:before {
  content: "\f165";
}
.npt-i-electrical-unit:before {
  content: "\f166";
}
.npt-i-enlarge-h:before {
  content: "\f167";
}
.npt-i-enlarge-v:before {
  content: "\f168";
}
.npt-i-level:before {
  content: "\f169";
}
.npt-i-logical-node:before {
  content: "\f16a";
}
.npt-i-marker-default:before {
  content: "\f16b";
}
.npt-i-marker-fix:before {
  content: "\f16c";
}
.npt-i-marker-point:before {
  content: "\f16d";
}
.npt-i-marker-zero:before {
  content: "\f16e";
}
.npt-i-ps:before {
  content: "\f16f";
}
.npt-i-reduce-h:before {
  content: "\f170";
}
.npt-i-reduce-v:before {
  content: "\f171";
}
.npt-i-ru:before {
  content: "\f172";
}
.npt-i-scheme-lan:before {
  content: "\f173";
}
.npt-i-scheme:before {
  content: "\f174";
}
.npt-i-vline:before {
  content: "\f175";
}
